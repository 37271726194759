import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 16,
    title: "AXIOS con React",
    desc: "L’ottimizzazione delle chiamate API",
    img: "/blog-image/axios.png",
    page: "blog/react-axios",
    data: "15 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "React si è affermato come uno dei principali framework JavaScript per lo sviluppo di applicazioni web moderne.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        React si è affermato come uno dei
                                        principali framework JavaScript per lo
                                        sviluppo di applicazioni web moderne.
                                        Una delle operazioni più comuni nello
                                        sviluppo di applicazioni React è gestire
                                        le chiamate API per recuperare i dati da
                                        server esterni o interagire con risorse
                                        remote. A questo scopo, AXIOS si è
                                        dimostrato uno strumento estremamente
                                        potente ed efficace.
                                    </p>

                                    <p>
                                        In questo articolo, esploriamo come
                                        sfruttare al meglio AXIOS con React per
                                        ottimizzare le chiamate API e migliorare
                                        le performance delle nostre
                                        applicazioni.
                                    </p>

                                    <h3>Cos'è AXIOS?</h3>

                                    <p>
                                        AXIOS è una libreria JavaScript per la
                                        gestione delle richieste HTTP basata su
                                        promise. È un tool ampiamente utilizzato
                                        nel mondo di React e tra le altre
                                        librerie JavaScript per effettuare
                                        chiamate API in modo asincrono. AXIOS
                                        offre una sintassi semplice e intuitiva
                                        per effettuare richieste GET, POST, PUT,
                                        DELETE e altre operazioni HTTP, rendendo
                                        la comunicazione con i server agevole.
                                    </p>

                                    <h3>
                                        Configurare AXIOS in un progetto React
                                    </h3>
                                    <p>
                                        Prima di poter utilizzare AXIOS in un
                                        progetto React, è necessario installarlo
                                        - si può procedere eseguendo il seguente
                                        comando:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`npm install axios`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Una volta installato AXIOS, si passa
                                        alla sua configurazione così da
                                        utilizzarlo nell’intero progetto React -
                                        è necessario creare un file di
                                        configurazione, ad esempio `axios.js`, e
                                        inserire il seguente codice:
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.example.com', // Sostituisci con l'URL della tua API
    timeout: 10000, // Imposta il timeout delle richieste (opzionale)
    headers: {
        'Content-Type': 'application/json',
    },
});

export default instance;
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Ora puoi importare questa istanza di
                                        AXIOS in qualsiasi parte del tuo
                                        progetto React e utilizzarla per
                                        effettuare chiamate API!
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import axios from './axios';

axios.get('/endpoint')
    .then(response => {
    // Gestisci la risposta qui
    })
    .catch(error => {
    // Gestisci l'errore qui
});
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h3>
                                        Gestire le Chiamate API in Componenti
                                        React
                                    </h3>
                                    <p>
                                        Al fine di massimizzare l'utilizzo di
                                        AXIOS in concomitanza con il framework
                                        React, è cruciale acquisire una
                                        comprensione approfondita del processo
                                        di gestione delle richieste API
                                        all'interno dei componenti React. Tali
                                        richieste vengono spesso inoltrate
                                        direttamente dai componenti React -
                                        l'impiego di AXIOS semplifica tale
                                        procedura.
                                    </p>
                                    <p>
                                        Supponiamo di avere un componente React
                                        che deve recuperare una lista di utenti
                                        da un'API. Ecco come potrebbe apparire
                                        il codice:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React, { useEffect, useState } from 'react';
import axios from './axios';

function UserList() {
    const [users, setUsers] = useState([]);
    
    useEffect(() => {
        axios.get('/users')
        .then(response => {
            setUsers(response.data);
        })
        .catch(error => {
            console.error('Errore nella richiesta API:', error);
        });
    }, []);
    
    return (
        <div>
            <h1>Lista Utenti</h1>
            <ul>
                {users.map(user => (
                <li key={user.id}>{user.name}</li>
                ))}
            </ul>
        </div>
    );
}

export default UserList;
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        In questo esempio, utilizziamo l'hook
                                        `useEffect` per effettuare la chiamata
                                        API quando il componente viene montato.
                                        Una volta ricevuti i dati dalla chiamata
                                        API, li immagazziniamo nello stato del
                                        componente utilizzando `useState`,
                                        consentendo al componente di
                                        renderizzare dinamicamente la lista
                                        degli utenti.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h3>Gestione efficace degli errori</h3>
                                    <p>
                                        La gestione degli errori costituisce un
                                        aspetto molto importante nell'ambito
                                        delle chiamate API. AXIOS agevola questo
                                        processo attraverso la fornitura del
                                        blocco `catch` nell'oggetto promise.
                                        Tuttavia, è imprescindibile attuare una
                                        gestione degli errori di carattere
                                        efficiente, con l'obiettivo di
                                        migliorare l'esperienza dell'utente.
                                    </p>
                                    <p>
                                        Un modo per farlo è mostrare un
                                        messaggio di errore all'utente quando si
                                        verifica un errore. Ad esempio:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React, { useEffect, useState } from 'react';
import axios from './axios';

function UserList() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        axios.get('/users')
        .then(response => {
            setUsers(response.data);
        })
        .catch(error => {
            setError(error);
        });
    }, []);
    
    if (error) {
        return <div>Si è verificato un errore: {error.message}</div>;
    }
    
    return (
        <div>
            <h1>Lista Utenti</h1>
            <ul>
                {users.map(user => (
                <li key={user.id}>{user.name}</li>
                ))}
            </ul>
        </div>
    );
}

export default UserList;
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        In questo esempio, abbiamo aggiunto uno
                                        stato `error` per memorizzare l'errore e
                                        lo mostriamo all'utente quando si
                                        verifica un errore.
                                    </p>
                                    <h3>
                                        Ottimizzazione delle chiamate API con
                                        interceptor
                                    </h3>
                                    <p>
                                        AXIOS mette a disposizione la
                                        possibilità di adoperare interceptor (o
                                        intercettori) allo scopo di amministrare
                                        in maniera centralizzata le richieste e
                                        le risposte relative alle chiamate API.
                                        Gli interceptor consentono
                                        l'implementazione di logiche
                                        personalizzate prima dell'invio di una
                                        richiesta o successivamente alla
                                        ricezione di una risposta.
                                    </p>
                                    <p>
                                        Un'applicazione comune degli
                                        intercettori consiste nell'inclusione di
                                        un token di autenticazione all'interno
                                        delle richieste. Ad esempio, se si
                                        dispone di un token JWT che deve essere
                                        conglobato in ciascuna richiesta, ciò
                                        può essere agevolmente effettuato
                                        mediante un interceptor operante in modo
                                        centralizzato.
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.example.com',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(config => {
    // Aggiungi il token JWT alle richieste se l'utente è autenticato
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
        config.headers.Authorization = 'Bearer ${"authToken"}';
    }
        return config;
    }, error => {
        return Promise.reject(error);
    });

export default instance; 
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        In questo esempio, utilizziamo un
                                        interceptor per aggiungere il token JWT
                                        alle richieste se l'utente è
                                        autenticato. Questo evita la necessità
                                        di aggiungere manualmente il token in
                                        ogni chiamata API.
                                    </p>
                                    <p>
                                        AXIOS è uno strumento potente e
                                        flessibile per la gestione delle
                                        chiamate API in applicazioni React.
                                        Sfruttando AXIOS in modo efficace, è
                                        possibile semplificare la comunicazione
                                        con le risorse remote, gestire gli
                                        errori in modo adeguato e ottimizzare le
                                        chiamate API per migliorare le
                                        performance complessive delle
                                        applicazioni.
                                    </p>
                                    <p>
                                        Se sei interessato a conoscere più
                                        approfonditamente AXIOS per ottimizzare
                                        i tuoi progetti React, contattaci e
                                        prenota una consulenza con il nostro
                                        team - ti guideremo verso le soluzioni
                                        più adatte al tuo business e verso una
                                        gestione avanzata delle chiamate API.
                                    </p>

                                    <p>Buon coding!</p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
